import React from 'react'
import './know.css'
import { useTranslation } from 'react-i18next'
import logo from '../../../assets/logo.png'
import { useNavigate } from "react-router-dom";

export const Know = () => {
    const { t } = useTranslation('global')
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/about')
    }

    return (
        <section className='about-home'>
            <div className='container about-container'>
                <div className='about-container-left'>
                    <img src={logo} alt="Logo Tejeda Ing-Büro"/>
                </div>
                <div className='about-container-right'>
                    <h2>{t('know.title')}</h2>
                    
                    <p>{t('know.p1')}</p>
                    <p>{t('know.p2')}</p>

                    <button onClick={handleClick} className='cta-contact'>
                        {t('know.talk')}
                    </button>
                </div>
            </div>
        </section>
    )
}
