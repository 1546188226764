import React from 'react'
import './members.css'
import BWK from '../../../assets/logo-BWK.png'
import DWA from '../../../assets/logo-DWA.png'
import IK from '../../../assets/logo-IK.png'
import BWKpdf from '../../../assets/doc/Teilnahmebestatigung Daseinsvorsorge Zukunft Wasser 36_BWK Bundeskongress.pdf' 
import IKpdf from '../../../assets/doc/urkunde mitglied in die liste der ingenieurkammer sachsen anhalt.pdf'
import DWApdf from '../../../assets/doc/mitglied der dwa tejeda ing-buro fur planung und projektmanagement.pdf'
import { useTranslation } from 'react-i18next'

export const Menbers = () => {

  const { t } = useTranslation('global')

  return (
    <section className='members-home'>
      <h2>{t('members.title')}</h2>

      <div className='container members-container'>
        <a href={BWKpdf} target="_blank" rel='noreferrer noopener'>
          <div className='img-members'>
            <img src={BWK} alt="logo BWK" />
          </div>
        </a>
        
        <a href={IKpdf} target='_blank' rel='noopener noreferrer'>
          <div className='img-members'>
            <img src={IK} alt="logo IK" />
          </div>
        </a>
        
        <a href={DWApdf} target='_blank' rel='noopener noreferrer'>
          <div className='img-members'>
            <img src={DWA} alt="logo DWA" />
          </div>
        </a>
      </div>
    </section>
  )
}
