import React from 'react'
import { HeaderPage } from '../shared/pageHeader/HeaderPage'
import { useTranslation } from 'react-i18next';
import './about.css'

import PlegableEN from '../../assets/doc/new/plegables english.pdf'
import PlegableDE from '../../assets/doc/new/plegables deutsch.pdf'
import PlegableES from "../../assets/doc/new/plegables espanol.pdf";
import cvEN from "../../assets/doc/new/Rodriguez_CV_English_diciembre 2023.pdf";
import cvDE from "../../assets/doc/new/Rodriguez_CV_deutsch_diciembre 2023.pdf";
import cvES from "../../assets/doc/new/Rodriguez_CV_español _diciembre 2023.pdf";
import ReferenceEN from '../../assets/doc/new/References until October 2022_english.pdf'
import ReferenceDE from '../../assets/doc/new/Referenzen bis Oktober 2022.pdf'
import ProfileEN from "../../assets/doc/new/profile_en.pdf";
import ProfileDE from "../../assets/doc/new/profile_de.pdf";
import ProfileES from "../../assets/doc/new/profile_es.pdf";
import Rainwater from '../../assets/doc/new/Zertifizierten Fachplaner Regenwassermanagement.pdf'
import Bim from '../../assets/doc/new/BIM Experte Zertifikat.pdf'


export const About = () => {

  const { t, i18n } = useTranslation('global')
  const lang = i18n.language

  console.log(lang)

  const getFlayer = () => {
    if (lang.startsWith("es")) return PlegableES;
    if (lang.startsWith("de")) return PlegableDE;
    return PlegableEN 
  }

  const getCv = () => {
		if (lang.startsWith("es")) return cvES;
		if (lang.startsWith("de")) return cvDE;
		return cvEN;
	};

  const getProfile = () => {
    if (lang.startsWith("es")) return ProfileES;
		if (lang.startsWith("de")) return ProfileDE;
		return ProfileEN;
  }

  return (
    <>
      <HeaderPage title={t('about.title')} />

      <main className='aboutUs-bady'>
        <div className='container aboutUs-container'>

          <h2>{t('about.tservice')}</h2>
          <p>{t('about.p1-service')}</p>
          <p>{t('about.p2-service')}</p>

          <h2>{t('about.tvisions')}</h2>
          <p>{t('about.p1-vision')}</p>
          <p>{t('about.p2-vision')}</p>

          <h2>{t('about.tmission')}</h2>
          <p>{t('about.p1-mission')}</p>



          <h2>{t('about.links')}</h2>

          <ul>
            <li>
              <a href={getCv()}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('about.cv')}
              </a>
            </li>

            <li>
              <a href={getProfile()}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('about.profile')}
              </a>
            </li>

            <li>
              <a href={lang === 'de' ? ReferenceDE : ReferenceEN}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('about.references')}
              </a>
            </li>

            <li>
              <a href={getFlayer()}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('about.flyer')}
              </a>
            </li>

            <li>
              <a href={Rainwater}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('about.rainwater')}
              </a>
            </li>
            <li>
              <a href={Bim}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('about.bim')}
              </a>
            </li>
          </ul>

          <h4>{t('about.taxNumber')}</h4>
          <h4>{t('about.insurance')}</h4>
          <h4>VHV Allgemeine Versicherung AG</h4>

        </div>
      </main>
    </>
  )
}
