import React from 'react'
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const NavLinks = (props) => {

    const {t} = useTranslation('global')
    const { isMobile, closeMobileMenu } = props

    const animateFrom = { opacity: 0, y: -40 }
    const animateTo = { opacity: 1, y: 0 }

    return (
        <>
            <ul>
                <motion.li
                    initial={animateFrom}
                    animate={animateTo}
                    transition={{delay: 0.05}}
                    onClick={() => isMobile && closeMobileMenu()}>
                    <Link to="/">{t('nav.home')}</Link>
                </motion.li>
                <motion.li
                    initial={animateFrom}
                    animate={animateTo}
                    transition={{ delay: 0.10 }}>
                    <Link to="/services">{t('nav.services')}</Link>
                </motion.li>
                <motion.li
                    initial={animateFrom}
                    animate={animateTo}
                    transition={{ delay: 0.18 }}
                    onClick={() => isMobile && closeMobileMenu()}>
                    <Link to="/tools">{t('nav.tools')}</Link>
                </motion.li>
                <motion.li
                    initial={animateFrom}
                    animate={animateTo}
                    transition={{ delay: 0.25 }}
                    onClick={() => isMobile && closeMobileMenu()}>
                    <Link to="/about">{t('nav.about')}</Link>
                </motion.li>
                <motion.li
                    initial={animateFrom}
                    animate={animateTo}
                    transition={{ delay: 0.30 }}
                    onClick={() => isMobile && closeMobileMenu()}>
                    <Link to="/contact">{t('nav.contact')}</Link>
                </motion.li>
            </ul>
        </>
    )
}

