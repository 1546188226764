import React from 'react'
import { useTranslation} from 'react-i18next'
import { HeaderPage } from '../shared/pageHeader/HeaderPage'
import { BiRadioCircle } from 'react-icons/bi'
import RESDM from '../../assets/resdm-app.png'
import './tools.css'


export const ToolsScreen = () => {

  const { t } = useTranslation('global')

  return (
    <>
      <HeaderPage title={t('tools.title')} />

      <section className='tools-body'>
        <div className='container tools-container'>
          <p>{t('tools.p1')}</p>
          <p>{t('tools.p2')}</p>

          <article className='articles-tools resdm'>
            <div className='logo-tool'>
              <img src={RESDM} alt='RESDM-LOGO' />
            </div>

            <div className='info-tool'>
              <h3>{t('tools.title-tool')}</h3>

              <p>{t('tools.description')}</p>

              <ul>
                <li className='feat-list'>
                  <BiRadioCircle className='icon-article-funtion' />
                  <p>{t('tools.feat1')}</p>
                </li>

                <li className='feat-list'>
                  <BiRadioCircle className='icon-article-funtion' />
                  <p>{t('tools.feat2')}</p>
                </li>

                <li className='feat-list'>
                  <BiRadioCircle className='icon-article-funtion' />
                  <p>{t('tools.feat3')}</p>
                </li>

                <li className='feat-list'>
                  <BiRadioCircle className='icon-article-funtion' />
                  <p>{t('tools.feat4')}</p>
                </li>
              </ul>

              <a href='https://resdm.com/' target='_blank' rel='noopener noreferrer'>
                {t('tools.test')}
              </a>

            </div>
          </article>
        </div>
      </section>
    </>
  )
}
