import React from 'react'
import './services.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
// import { FaHandHoldingWater } from 'react-icons/fa'
// import { MdHomeWork } from 'react-icons/md'
// import { GiRiver } from 'react-icons/gi'

import Water from '../../../assets/wasser.png'
import Bau from '../../../assets/BAU.png'
import Env from '../../../assets/umwelt.png'

export const Services = () => {

    const { t } = useTranslation('global')
    const navigate = useNavigate()

    

    const handleclickwater = () => {
        navigate('/services/#waterView',)
    }

    const handleclickConst = () => {
        navigate('/services/#construction')
    }

    const handleclickTraining = () => {
        navigate('/services/#training')
    }

    return (
        <section className='services-home'>
            <h2>{t('services.title')}</h2>
            <p className='container'>{t('services.p')}</p>

            <div className='container services-container'>
                <div className='service-card'>
                    <div className='icons-container'>
                        {/* <FaHandHoldingWater className='icons' /> */}
                        <img src={ Water } alt="water management" />
                    </div>

                    <div className='card-content'>
                        <h3>{t('services.water')}</h3>
                        {/* <p> Water and environment is the most important sector of our company.</p> */}
                        <button onClick={handleclickwater}>{t('services.view')}</button>
                    </div>
                </div>

                <div className='service-card'>
                    <div className='icons-container'>
                        {/* <GiRiver className='icons' /> */}
                        <img src={Env} alt="enviroment" />
                    </div>
                    <div className='card-content'>
                        <h3>{t('services.env')}</h3>
                        {/* <p> Water and environment is the most important sector of our company.</p> */}
                        <button onClick={handleclickConst}>{t('services.view')}</button>
                    </div>
                </div>

                <div className='service-card'>
                    <div className='icons-container'>
                        {/* <MdHomeWork className='icons' /> */}
                        <img src={Bau} alt="Contruction company" />
                    </div>
                    <div className='card-content'>
                        <h3>{t('services.construction')}</h3>
                        {/* <p> Water and environment is the most important sector of our company.</p> */}
                        <button onClick={handleclickTraining}>{t('services.view')}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}
