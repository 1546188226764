import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";


export const CTA = () => {

  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/contact')
  }

  return (
    <button onClick={handleClick} className='cta-contact'>
        {t('header.talk')}
    </button>
  )
}
