import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { About } from '../components/about/About'
import { ContactScreen } from '../components/contact/ContactScreen'
import { Home } from '../components/home/Home'
import { Services } from '../components/services/Services'
import { Footer } from '../components/shared/footer/Footer'
import { Nav } from '../components/shared/navbar/Nav'
import { ToolsScreen } from '../components/tools/ToolsScreen'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/tools' element={<ToolsScreen />} />
        <Route path='/contact' element={<ContactScreen />} />
        <Route path="/about" element={<About />} />
        
        <Route path="/*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}
