import React, { useState } from 'react'
import { Navigation } from './Navigation'
import { MobileNavigation } from "./MobileNavigation"
import './nav.css'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiFillCloseCircle } from 'react-icons/ai'

import { LangButton } from './LangButton'
import { Link, useLocation } from 'react-router-dom'


export const Nav = () => {

  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(false)
  const location = useLocation()

  console.log(location)
  

  const hamburgerIcon = <button type="button" className='collapse' onClick={() => setOpen(!open)}>
    <GiHamburgerMenu className='collapse_icon' />
  </button>

  const closeIcon = <button type="button" className='collapse' onClick={() => setOpen(!open)}>
    <AiFillCloseCircle className='collapse_icon' />
  </button>


  const closeMobileMenu = () => setOpen(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if(window.scrollY >= 80) {
      setActive(true)
    } else {
      setActive(false)
    }
  }

  window.addEventListener('scroll', changeBackground)

  return (
    <nav className={active || location.pathname === '/services' ? 'navbar active' : 'navbar' }>
      <div className='container nav_container'>
        <div className='logo_container'>
          <Link to='/' className='logo'>Tejeda Ing.Büro</Link>
          {open ? closeIcon : hamburgerIcon}
        </div>

        <div className='menu_container'>
          <Navigation />
          {open && <MobileNavigation isMobile={true} closeMobileMenu={closeMobileMenu} />}
        </div>

        <LangButton/>


      </div>
    </nav>
  )
}

