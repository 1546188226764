import React from 'react'
import { Know } from './about/Know'
// import { Different } from './different/Different'
import { Header } from './header/Header'
import { Menbers } from './members/Menbers'
import { Services } from './services/Services'

export const Home = () => {
  return (
    <>
      <Header />
      <Know />
      <Services />
      {/* <Different /> */}
      <Menbers />
    </>
  )
}
