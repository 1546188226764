import React from 'react'
import './headerPage.css'

export const HeaderPage = ({title}) => {

    return (
        <header className='header-page'>
            <h1>{title}</h1>
        </header>
    )
}
