import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

import './contact.css'
import { useTranslation } from 'react-i18next'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { IoMdMail } from 'react-icons/io'
import { ImLocation } from 'react-icons/im'
import { HeaderPage } from '../shared/pageHeader/HeaderPage'
import { Toast } from '../shared/toast/Toast';

export const ContactScreen = () => {

  const [active, setActive] = useState(false)
  const { t } = useTranslation('global')

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zhse7cr', 'template_oj6g5rk', form.current, 'YJ52utkUi4he4Ypvm')
      .then((result) => {
        console.log(result.text);
        setActive(true)
        setTimeout(() => {
          setActive(false)
        }, 1800)
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset()
  };

  return (
    <>
      <HeaderPage title={t('contact.title')}/>

      <section className='contact-body'>
        <div className='container contact-container'>
          <div className='info-container'>
            <h3>{t('contact.info')}</h3>
            <p>{t('contact.p')}</p>
            <p>{t('contact.p2')}</p>

            <div className='contact-option'>
              <BsFillTelephoneFill className='icons-contact' />
              <span>+49 1577 9850 304</span>
            </div>

            <div className='contact-option'>
              <IoMdMail className='icons-contact' />
              <span>tejeda@tejedaingburo.com</span>
            </div>

            <div className='contact-option'>
              <ImLocation className='icons-contact' />
              <span>Ernst-Thälmann-Straße 26, 39365 Eilsleben, Germany</span>
            </div>
          </div>

          <div className='form-container'>
            <h3>{t('contact.title2')}</h3>
            <form className='form-contact' ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="name"
                placeholder={t('contact.name')}
                autoComplete='none'
                required
              />
              <input
                type="email"
                name="email"
                placeholder={t('contact.email')}
                autoComplete='none'
                required
              />
              <textarea
                rows="13"
                name="message"
                placeholder={t('contact.message')}
                required
              ></textarea>
              <button type='submit'>{t('contact.send')}</button>
            </form>
          </div>

          

        </div>

        <Toast active={active} />
      </section>
    </>
  )
}
