import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import './services.css'
import Water from  '../../assets/water fix.png'
import Construt from '../../assets/construction fix.png'
import Env from '../../assets/environment fix.png'

export const Services = () => {

    const { t } = useTranslation('global')

    // go to hash
    const { hash } = useLocation()

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [ hash ]); // do this on route change

    return (
        <main>
            <div className='container services-screen-container'>
                <section id='waterView' className='art-sect water-sect'>
                    <h2  className='title-sector'>
                        {t('servicesScreen.twater')} 
                    </h2>
                    <article className=' grid-art'>
                        <div className='image-art'>
                            <img src={Water} alt='water' />
                        </div>

                        <div className='art-content'>
                            <h3>{t('servicesScreen.services')}</h3>
                            <ul>
                                <li>{t('servicesScreen.water1')}</li>
                                <li>{t('servicesScreen.water2')}</li>
                                <li>{t('servicesScreen.water3')}</li>
                                <li>{t('servicesScreen.water4')}</li>
                                <li>{t('servicesScreen.water5')}</li>
                                <li>{t('servicesScreen.water6')}</li>
                                <li>{t('servicesScreen.water7')}</li>
                                <li>{t('servicesScreen.water8')}</li>
                                <li>{t('servicesScreen.water9')}</li>
                                <li>{t('servicesScreen.water10')}</li>
                                <li>{t('servicesScreen.water11')}</li>
                                <li>{t('servicesScreen.water12')}</li>
                                <li>{t('servicesScreen.water13')}</li>
                                <li>{t('servicesScreen.water14')}</li>
                                <li>{t('servicesScreen.water15')}</li>
                                <li>{t('servicesScreen.water16')}</li>
                                <li>{t('servicesScreen.water17')}</li>
                            </ul>
                        </div>
                    </article>
                </section>

                <section id='construction' className='art-sect construction-sect'>
                    <h2 className='title-sector'>{t('servicesScreen.tenvironment')}</h2>
                    <article className='grid-art'>

                        <div className='art-content'>
                            <h3>{t('servicesScreen.services')}</h3>
                            <ul>
                                <li>{t('servicesScreen.environment1')}</li>
                                <li>{t('servicesScreen.environment2')}</li>
                                <li>{t('servicesScreen.environment3')}</li>
                            </ul>
                        </div>

                        <div className='image-art inverted'>
                            <img src={Env} alt='environment' />
                        </div>
                    </article>
                </section>

                <section id='training' className='art-sect edu-sect'>
                    <h2 className='title-sector'>{t('servicesScreen.tconstruction')}</h2>
                    <article className='grid-art'>
                        <div className='image-art'>
                            <img src={Construt} alt='Construction' />
                        </div>

                        <div className='art-content'>
                            {/* <h3>Water management: a global challenge.</h3>
                            <p>Global warming and expanding populations mean that ‘water stress’ – insufficient resources to meet needs – is a threat to us all. In tackling this challenge, we’re fully committed to achieving Goal 6 of the UN’s 2030 Agenda for Sustainable Development, which aims to ensure everyone’s access to water and sanitation.</p> */}
                            <h3>{t('servicesScreen.services')}</h3>
                            <ul>
                                <li>{t('servicesScreen.construcction1')}</li>
                                <li>{t('servicesScreen.construcction2')}</li>
                                <li>{t('servicesScreen.construcction3')}</li>
                            </ul>
                        </div>
                    </article>
                </section>

            </div>
        </main>
    )
}
