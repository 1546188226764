import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import EN from "../../../assets/en-flag.png";
import DE from "../../../assets/de-flag.png";
import ES from "../../../assets/es-flag.png";
import { useTranslation } from "react-i18next";

export const LangButton = () => {
	// eslint-disable-next-line
	const [t, i18n] = useTranslation("global");

	const [lang, setLang] = useState(i18n.language);
	const [active, setActive] = useState(false);

	const handleClickLangButton = () => setActive(!active);

	const handleClickLang = (lang) => {
		setLang(lang);
		i18n.changeLanguage(lang);
		setActive(false);
	};

	const drawFlag = () => {
		if (lang.startsWith("es")) {
            console.log('sdf')
			return <img src={ES} alt='lenguage ES' className='flag' />;
		}
		if (lang.startsWith("de")) {
			return <img src={DE} alt='lenguage DE' className='flag' />;
		}
	
		return <img src={EN} alt='lenguage en' className='flag' />;
	};

	return (
		<div className='lenguaje-container'>
			<div className='select-btn' onClick={handleClickLangButton}>
				{drawFlag()}
				<MdKeyboardArrowDown className='arrow-icon' />
			</div>

			<ul className={active ? "list-lang" : "list-lang disabled"}>
				<li onClick={() => handleClickLang("en")}>
					<img src={EN} alt='lenguage EN' className='flag' />
					<span className='option-text'>EN</span>
				</li>
				<li onClick={() => handleClickLang("de")}>
					<img src={DE} alt='lenguage DE' className='flag' />
					<span className='option-text'>DE</span>
				</li>
				<li onClick={() => handleClickLang("es")}>
					<img src={ES} alt='lenguage ES' className='flag' />
					<span className='option-text'>ES</span>
				</li>
			</ul>
		</div>
	);
};
