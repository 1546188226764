import React from 'react'
import { useTranslation } from 'react-i18next'
import { CTA } from './CTA'
import './header.css'

export const Header = () => {

  const {t} = useTranslation('global')

  return (
    <header className='header-home'>
      <div className='header-overlay'></div>
      <section className='container header-container'>
        <h1>{t('header.title')}</h1>
        <p className='typewriter'>{t('header.title2')}</p>
        <CTA />
      </section>
    </header>
  )
}
