import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import global_en from '../languages/en/global.json'
import global_de from '../languages/de/global.json'
import global_es from '../languages/es/global.json'

let ln = window.navigator.language || navigator.browserLanguage;

if (!ln.startsWith("en") && !ln.startsWith("de") && !ln.startsWith("es")) {
	ln = "en";
}

i18n.use(initReactI18next).init({
    lng: ln,
    fallbackLng: 'de',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            global: global_en
        },
        de: {
            global: global_de
        },
        es: {
            global: global_es
        }
    }
})