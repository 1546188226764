import React from 'react'
import { Link } from 'react-router-dom';
import './footer.css'
import { FaFacebookF } from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs'
import { BsTwitter } from 'react-icons/bs'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { IoMdMail } from 'react-icons/io'
import { ImLocation } from 'react-icons/im'
import { useTranslation } from 'react-i18next';

export const Footer = () => {

  const { t } = useTranslation('global')

  return (
    <footer id='footer'>
      <div className='container footer-container'>
        <div className='sections-container'>
          

          <div className='footer-list-container'>
            <h4>{t('footer.tSector')}</h4>
            <ul>
              <li>
                <Link to='/services/#waterView'>{t('footer.water')}</Link>
              </li>
              <li>
                <Link to='/services/#construction'>{t('footer.construction')}</Link>
              </li>
              <li>
                <Link to='/services/#training'>{t('footer.training')}</Link>
              </li>
            </ul>
          </div>

          <div className='footer-list-container'>
            <h4>{t('footer.tLinks')}</h4>
            <ul>
              <li>
                <Link to='/'>{t('footer.home')}</Link>
              </li>
              <li>
                <Link to='/services'>{t('footer.services')}</Link>
              </li>
              <li>
                <Link to='/about'>{t('footer.about')}</Link>
              </li>
              <li>
                <Link to='/tools'>{t('footer.tools')}</Link>
              </li>
            </ul>
          </div>

          <div className='footer-list-container'>
            <h4>{t('footer.tPolicie')}</h4>
            <ul>
              <li>
                <Link to='/'>{t('footer.cookies')}</Link>
              </li>
              <li>
                <Link to='/'>{t('footer.privacy')}</Link>
              </li>
            </ul>
          </div>

          <div className='footer-list-container' id='contact'>
            <h4>{t('footer.tContact')}</h4>
            <ul>
              <li >
                <BsFillTelephoneFill className='icons-contact-footer' />
                <span>+49 1577 9850 304</span>
              </li>
              <li >
                <IoMdMail className='icons-contact-footer' />
                <span>tejeda@tejedaingburo.com</span>
              </li>
              <li >
                <ImLocation className='icons-contact-footer' />
                <span>Ernst-Thälmann-Straße 26, 39365 Eilsleben, Germany</span>
              </li>
            </ul>
          </div>
          
        </div>

        <div className='copy-container'>
          <span>&copy; 2022 Tejeda Ing.Büro</span>
          <div className='social-icons'>
            <FaFacebookF className='icon' />
            <BsTwitter className='icon' />
            <BsLinkedin className='icon' />
          </div>
        </div>

      </div>
    </footer>
  )
}

