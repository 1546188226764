import React from 'react'

import { AppRouter } from './routers/AppRouter'

export const App = () => {
  return (
    <>
      <AppRouter />
    </>
    
  )
}

